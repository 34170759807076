@media only screen and (max-width: 499px) {
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }


  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 250px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 174px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .carouselIMG {
    width: 200px;
    height: 200px;
    border: 2px solid white;

  }

  .slider .slide {
    width: 100px;
    height: 174px;
    padding-left: 3.2%;
    padding-right: 3.2%;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }

}


@media only screen and (min-width: 500px) {
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  .carouselIMG {
    width: 240px;
    height: 240px;
    border: 2px solid white;

  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 300px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 174px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide {
    height: 174px;
    width: 100px;
    padding-left: 3.8%;
    padding-right: 3.8%;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media only screen and (min-width: 768px) {
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  .carouselIMG {
    width: 250px;
    height: 250px;
    border: 2px solid white;

  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 350px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 125px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide {
    height: 250px;
    width: 435px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media only screen and (min-width: 992px) {
  .carouselIMG {
    width: 250px;
    height: 250px;
    border: 2px solid white;

  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    height: 350px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    margin-top: 1%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 435px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide {
    height: 250px;
    width: 435px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {
  .carouselIMG {
    width: 390px;
    height: 390px; 
    border: 2px solid white;
  
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    height: 480px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    margin-top: 1%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 435px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide {
    height: 250px;
    width: 435px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 2560px) {
  .carouselIMG {
    width: 800px;
    height: 800px; 
    border: 2px solid white;
  
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    height: 850px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    margin-top: 1%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 435px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide {
    height: 250px;
    width: 435px;
    padding-left: 12%;
    padding-right: 12%;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 3840px) {
  .carouselIMG {
    width: 800px;
    height: 800px; 
    border: 2px solid white;
  
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  .slider {
    background: rgba(255, 255, 255, 0);
    height: 850px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    margin-top: 1%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 435px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide {
    height: 250px;
    width: 435px;
    padding-left: 12%;
    padding-right: 12%;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}