* {
  font-family: 'Michroma', sans-serif;
}

 form{
  background-color: #2e8b5600;
  border: none;
  padding: 0;
  color: white;
}

.walletConnectNew:hover{
  color: #fea676;
}

@media screen and (max-width: 499px) {

  .aLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: #f8c831;
  }
  
  .walletLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .cT2 a {
    color: #f8c831;
    text-decoration: none;
  }
  
  .wallet2M a {
    font-family: 'KG The Last Time', sans-serif;
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .wallet2M a:hover {
    color: #f8c831;
  }
  
  .connectAndSocial {
    display: flex;
    flex-flow: column;
    width: 95%;
  }
  
  .button3 {
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 3.5%;
    text-align: left;
    border: none;
    background-color: #fea676;
    color: white;
    font-weight: lighter;
    font-size: 14px;
    transition: 0.3s ease;
  }
  
  .walletadd{
    color: white;
    background-color: #bdfe00;
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: left;
    border: none;
  
    font-weight: lighter;
    font-size: 16px;
  }
  
  
  .button3:hover {
    color: #fea676;
    background-color: white;
  }
  
  .button3:focus {
    color: white;
    background-color: #bdfe00;
  }
  
  .connectImg {
    width: 100%;
    z-index: 1000;
    margin-bottom: 5%;
  }
  
  .connect2M {
    display: none;
  }
  
  .connect2-2M {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .discord4 {
    display: none;
  }
  
  .com {
    z-index: 1000;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    margin-top: -10%;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    opacity: 0.6;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .osPic {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .storyMain {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('./assets/cave.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Light {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .boxWrap2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap2-3 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
  }
  
  .boxWrap7 {
    padding-top: 3%;
    padding-bottom: 3%;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap8 {
    padding-top: 25%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .goldGost {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .goldGost2 {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
    border: 3px solid white;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: #6bba5e;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .boxWrap3 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3%;
    padding-bottom: 6%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .carouselH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 10%;
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .storyCon2-2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  }
  
  .storyCon2-3 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    font-style: italic;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 60%;
    background-color: #212b3f81;
    padding: 20px;
    margin-right: 10%;
    border-radius: 10px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes p {
    color: rgba(255, 255, 255, 0.753);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .download {
    width: 25px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
  
    }
  
  }
  
  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .storyCon2 {
    color: white;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .underBenefits {
    margin-top: 5%;
    z-index: 1000;
  
  }
  
  .aboutT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    z-index: 1;
    margin-bottom: 3%;
    margin-top: 5%;
  }
  
  .rmT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  }
  
  .memName {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .teamH {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .subT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: left;
  }
  
  .cT {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    z-index: 10000;
  }
  
  .cP {
    font-size: 16px;
    color: white;
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.658);
    padding: 20px;
    border-radius: 50px;
    border: 2px white solid;
  }
  
  .carouselT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  
  }
  
  .carouselT2 {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    display: none;
  }
  
  .cMain {
    z-index: 1;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .team1 {
    width: 30%;
  }
  
  .team1-1 {
    width: 30%;
  }
  
  .storyRight {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
  }
  
  .storyRight2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-bottom: 20%;
    z-index: 1000;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: #f8c831;
    text-align: center;
    font-family: 'KG The Last Time', sans-serif;
    text-shadow: 0 0 7px #f8c831;
  }
  
  .intro img {
    width: 30%;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    text-align: center;
    margin-top: 1%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 10%;
    font-size: 15px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    justify-content: flex-end;
    height: fit-content;
    display: none;
  }

  .rightMobile {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    right: 15%;
    height: fit-content;
    position: fixed;
    bottom: 7%;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    width: 5%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .discord {
    transition: transform .2s;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .logoF {
    padding-bottom: 1%;
    font-size: 40px;
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: center;
    z-index: 20;
    position: relative;
  }
  
  .discord:hover {
    transform: scale(1.4);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitter {
   width: 25px;
   height: 25px;
   height: 25px;
   display: flex;
   margin-top: auto;
   margin-bottom: auto;
   justify-content: center;
   cursor: pointer;
  }
  
  .twitterDiv{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
   }
  
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30%;
    z-index: 1;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
    transition-duration: .2s;
  }
  
  .connect div:hover {
    color: #f8c831;
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #fe7fc0;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Comic Kings', sans-serif;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: auto;
    margin-right: auto;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    z-index: 1000;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-weight: 500;
    font-size: 17px;
    position: fixed;
     margin-top: 25%;
    bottom: 0;
     width: 100%;
  }
  
  .gif {
    background: url('./assets/sky.gif');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }
  
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .vidCont4 {
    object-fit: cover;
    width: 100%;
  
  }
  
  .videoBg4 {
    position: absolute;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
  }
  
  .vidCont3 {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #2E8B57;
  }
  
  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }
  
  .videoBg2 {
    left: 0px;
    max-width: 100%;
    min-height: 100%;
    background-size: contain;
    position: absolute;
    top: 0;
  }
  
  .videoBg3 {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .name {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .logoDiv {
    width: 90px;
    height: 42px;
  }
  
  .wallet2 {
    background-color: #f8c831;
    color: rgb(23, 7, 66);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 7px;
    font-family: 'KG The Last Time', sans-serif;
    transition-duration: .5s;
  
  }
  
  .learn {
    background-color: #fe7fc100;
    color: white;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(235, 163, 9), rgb(255, 174, 1)) 1;
    transition: transform .4s;
    margin-top: 3%;
    background: -webkit-linear-gradient(rgb(255, 174, 1), rgb(255, 174, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  .learn:hover {
    transform: scale(1.5);
  }
  
  
  #learnFont {
    font-size: 8px;
  }
  
  
  .wallet2:hover {
    background-color: rgb(9, 52, 109);
    color: #f8c831;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    background-color: rgba(202, 55, 55, 0);
    padding: 10%;
    z-index: 20;
    margin-top: 20%;
    position: relative;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    z-index: 20;
    position: relative;
    font-size: 13px;
  }
  
  .socialFDiv {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    z-index: 20;
    position: relative;
  }
  
  .socialF {
    width: 40px;
    height: 40px;
    transition: transform .4s;
    z-index: 100000;
  }
  
  .socialF:hover {
    transform: scale(1.6);
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  #fontSize {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .cover {
    width: 100vw;
  }
  
  .parrotPic {
    width: 50%;
  }
  
  .parrot {
    width: 70%;
  }
  
  .logo2 {
    width: 50%;
    margin-top: 5%;
  }
  
  .blackLine {
    width: fit-content;
  }
  
  .p1 {
    font-size: 14px;
    margin-bottom: 2%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    border-left-color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    border-right-color: #f9f50500;
    border-top-color: #f9f50500;
    border-bottom-color: #f9f50500;
    border-style: dashed;
    border-width: 5px;
    padding-left: 70px;
    padding-bottom: 20px;
  }
  
  .giveaways {
    color: white;
    text-align: left;
    font-weight: 500;
    flex-flow: column nowrap;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .gaPic {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  .section1Li {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    z-index: 1;
  }
  
  .gaPic2 {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  
  .gaPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: block;
    margin-right: 5%;
  
  }
  
  .noteGiveaway {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 17px;
  }
  
  .noteGiveaway2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 16px;
    color: white;
  }
  
  .picAndnote {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmBox {
    color: white;
  }
  
  .section1give {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmMain {
    flex-flow: column nowrap;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .phase {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: left;
  }
  
  .rmCon {
    color: white;
    width: 90%;
    text-align: justify;
    font-size: 20px;
    margin-top: -2%;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 40%;
    margin-bottom: -40%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    background-color: #2E8B57;
  }
  
  .man {
    width: 300px;
    height: 300px;
  }
  
  .manDiv {
    background-color: #3a9ae8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  * {
      box-sizing: border-box;
    }
  
    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    .ticker-wrap {
      width: 100%;
      overflow: hidden;
      height: 2rem;
      background-color: #ffffff;
      padding-left: 100%;
      position: fixed;
      bottom: 0;
    }
  
    .ticker-wrap .ticker {
      display: inline-block;
      height: 2rem;
      line-height: 2rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 30s;
      animation-duration: 50s;
    }
  
    .ticker-wrap .ticker__item {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 14px;
      color: black;
      font-weight: 600;
    }
  
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .aLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: #f8c831;
  }
  
  .walletLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .cT2 a {
    color: #f8c831;
    text-decoration: none;
  }
  
  .wallet2M a {
    font-family: 'KG The Last Time', sans-serif;
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .wallet2M a:hover {
    color: #f8c831;
  }
  
  .connectAndSocial {
    display: flex;
    flex-flow: column;
    width: 80%;
  }
  
  .button3 {
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 3.5%;
    text-align: left;
    border: none;
    background-color: #fea676;
    color: white;
    font-weight: lighter;
    font-size: 14px;
    transition: 0.3s ease;
  }
  
  .walletadd{
    color: white;
    background-color: #bdfe00;
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: left;
    border: none;
  
    font-weight: lighter;
    font-size: 16px;
  }
  
  
  .button3:hover {
    color: #fea676;
    background-color: white;
  }
  
  .button3:focus {
    color: white;
    background-color: #bdfe00;
  }
  
  .connectImg {
    width: 100%;
    z-index: 1000;
    margin-bottom: 5%;
  }
  
  .connect2M {
    display: none;
  }
  
  .connect2-2M {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .discord4 {
    display: none;
  }
  
  .com {
    z-index: 1000;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    margin-top: -10%;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    opacity: 0.6;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .osPic {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .storyMain {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('./assets/cave.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Light {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .boxWrap2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap2-3 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
  }
  
  .boxWrap7 {
    padding-top: 3%;
    padding-bottom: 3%;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap8 {
    padding-top: 25%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .goldGost {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .goldGost2 {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
    border: 3px solid white;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: #6bba5e;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .boxWrap3 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3%;
    padding-bottom: 6%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .carouselH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 10%;
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .storyCon2-2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  }
  
  .storyCon2-3 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    font-style: italic;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 60%;
    background-color: #212b3f81;
    padding: 20px;
    margin-right: 10%;
    border-radius: 10px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes p {
    color: rgba(255, 255, 255, 0.753);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .download {
    width: 25px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
  
    }
  
  }
  
  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .storyCon2 {
    color: white;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .underBenefits {
    margin-top: 5%;
    z-index: 1000;
  
  }
  
  .aboutT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    z-index: 1;
    margin-bottom: 3%;
    margin-top: 5%;
  }
  
  .rmT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  }
  
  .memName {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .teamH {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .subT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: left;
  }
  
  .cT {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    z-index: 10000;
  }
  
  .cP {
    font-size: 16px;
    color: white;
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.658);
    padding: 20px;
    border-radius: 50px;
    border: 2px white solid;
  }
  
  .carouselT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  
  }
  
  .carouselT2 {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    display: none;
  }
  
  .cMain {
    z-index: 1;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .team1 {
    width: 30%;
  }
  
  .team1-1 {
    width: 30%;
  }
  
  .storyRight {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
  }
  
  .storyRight2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-bottom: 20%;
    z-index: 1000;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: #f8c831;
    text-align: center;
    font-family: 'KG The Last Time', sans-serif;
    text-shadow: 0 0 7px #f8c831;
  }
  
  .intro img {
    width: 30%;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    text-align: center;
    margin-top: 1%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 10%;
    font-size: 15px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    justify-content: flex-end;
    height: fit-content;
    display: none;
  }

  .rightMobile {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    right: 15%;
    height: fit-content;
    position: fixed;
    bottom: 7%;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    width: 5%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .discord {
    transition: transform .2s;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .logoF {
    padding-bottom: 1%;
    font-size: 40px;
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: center;
    z-index: 20;
    position: relative;
  }
  
  .discord:hover {
    transform: scale(1.4);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitter {
   width: 25px;
   height: 25px;
   height: 25px;
   display: flex;
   margin-top: auto;
   margin-bottom: auto;
   justify-content: center;
   cursor: pointer;

  }
  
  .twitterDiv{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
   }
  
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30%;
    z-index: 1;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
    transition-duration: .2s;
  }
  
  .connect div:hover {
    color: #f8c831;
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #fe7fc0;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Comic Kings', sans-serif;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: auto;
    margin-right: auto;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    z-index: 1000;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-weight: 500;
    font-size: 17px;
    position: fixed;
     margin-top: 25%;
    bottom: 0;
     width: 100%;
  }
  
  .gif {
    background: url('./assets/sky.gif');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }
  
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .vidCont4 {
    object-fit: cover;
    width: 100%;
  
  }
  
  .videoBg4 {
    position: absolute;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
  }
  
  .vidCont3 {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #2E8B57;
  }
  
  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }
  
  .videoBg2 {
    left: 0px;
    max-width: 100%;
    min-height: 100%;
    background-size: contain;
    position: absolute;
    top: 0;
  }
  
  .videoBg3 {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .name {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .logoDiv {
    width: 90px;
    height: 42px;
  }
  
  .wallet2 {
    background-color: #f8c831;
    color: rgb(23, 7, 66);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 7px;
    font-family: 'KG The Last Time', sans-serif;
    transition-duration: .5s;
  
  }
  
  .learn {
    background-color: #fe7fc100;
    color: white;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(235, 163, 9), rgb(255, 174, 1)) 1;
    transition: transform .4s;
    margin-top: 3%;
    background: -webkit-linear-gradient(rgb(255, 174, 1), rgb(255, 174, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  .learn:hover {
    transform: scale(1.5);
  }
  
  
  #learnFont {
    font-size: 8px;
  }
  
  
  .wallet2:hover {
    background-color: rgb(9, 52, 109);
    color: #f8c831;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    background-color: rgba(202, 55, 55, 0);
    padding: 10%;
    z-index: 20;
    margin-top: 20%;
    position: relative;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    z-index: 20;
    position: relative;
    font-size: 13px;
  }
  
  .socialFDiv {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    z-index: 20;
    position: relative;
  }
  
  .socialF {
    width: 40px;
    height: 40px;
    transition: transform .4s;
    z-index: 100000;
  }
  
  .socialF:hover {
    transform: scale(1.6);
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  #fontSize {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .cover {
    width: 100vw;
  }
  
  .parrotPic {
    width: 50%;
  }
  
  .parrot {
    width: 70%;
  }
  
  .logo2 {
    width: 50%;
    margin-top: 5%;
  }
  
  .blackLine {
    width: fit-content;
  }
  
  .p1 {
    font-size: 14px;
    margin-bottom: 2%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    border-left-color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    border-right-color: #f9f50500;
    border-top-color: #f9f50500;
    border-bottom-color: #f9f50500;
    border-style: dashed;
    border-width: 5px;
    padding-left: 70px;
    padding-bottom: 20px;
  }
  
  .giveaways {
    color: white;
    text-align: left;
    font-weight: 500;
    flex-flow: column nowrap;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .gaPic {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  .section1Li {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    z-index: 1;
  }
  
  .gaPic2 {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  
  .gaPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: block;
    margin-right: 5%;
  
  }
  
  .noteGiveaway {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 17px;
  }
  
  .noteGiveaway2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 16px;
    color: white;
  }
  
  .picAndnote {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmBox {
    color: white;
  }
  
  .section1give {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmMain {
    flex-flow: column nowrap;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .phase {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: left;
  }
  
  .rmCon {
    color: white;
    width: 90%;
    text-align: justify;
    font-size: 20px;
    margin-top: -2%;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 40%;
    margin-bottom: -40%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    background-color: #2E8B57;
  }
  
  .man {
    width: 300px;
    height: 300px;
  }
  
  .manDiv {
    background-color: #3a9ae8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  * {
      box-sizing: border-box;
    }
  
    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    .ticker-wrap {
      width: 100%;
      overflow: hidden;
      height: 2rem;
      background-color: #ffffff;
      padding-left: 100%;
      position: fixed;
      bottom: 0;
    }
  
    .ticker-wrap .ticker {
      display: inline-block;
      height: 2rem;
      line-height: 2rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 30s;
      animation-duration: 50s;
    }
  
    .ticker-wrap .ticker__item {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 16px;
      color: black;
      font-weight: 600;
    }
  
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .aLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: #f8c831;
  }
  
  .walletLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .cT2 a {
    color: #f8c831;
    text-decoration: none;
  }
  
  .wallet2M a {
    font-family: 'KG The Last Time', sans-serif;
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .wallet2M a:hover {
    color: #f8c831;
  }
  
  .connectAndSocial {
    display: flex;
    flex-flow: column;
    width: 60%;
  }
  
  .button3 {
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 1%;
    text-align: left;
    border: none;
    background-color: #fea676;
    color: white;
    font-weight: lighter;
    font-size: 14px;
    transition: 0.3s ease;
  }
  
  .walletadd{
    color: white;
    background-color: #bdfe00;
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: left;
    border: none;
  
    font-weight: lighter;
    font-size: 16px;
  }
  
  
  .button3:hover {
    color: #fea676;
    background-color: white;
  }
  
  .button3:focus {
    color: white;
    background-color: #bdfe00;
  }
  
  .connectImg {
    width: 90%;
    z-index: 1000;
    margin-bottom: 5%;
  }
  
  .connect2M {
    display: none;
  }
  
  .connect2-2M {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .discord4 {
    display: none;
  }
  
  .com {
    z-index: 1000;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    margin-top: -10%;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    opacity: 0.6;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .osPic {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .storyMain {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('./assets/cave.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Light {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .boxWrap2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap2-3 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
  }
  
  .boxWrap7 {
    padding-top: 3%;
    padding-bottom: 3%;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap8 {
    padding-top: 25%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .goldGost {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .goldGost2 {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
    border: 3px solid white;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: #6bba5e;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .boxWrap3 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3%;
    padding-bottom: 6%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .carouselH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 10%;
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .storyCon2-2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  }
  
  .storyCon2-3 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    font-style: italic;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 60%;
    background-color: #212b3f81;
    padding: 20px;
    margin-right: 10%;
    border-radius: 10px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes p {
    color: rgba(255, 255, 255, 0.753);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .download {
    width: 25px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
  
    }
  
  }
  
  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .storyCon2 {
    color: white;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .underBenefits {
    margin-top: 5%;
    z-index: 1000;
  
  }
  
  .aboutT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    z-index: 1;
    margin-bottom: 3%;
    margin-top: 5%;
  }
  
  .rmT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  }
  
  .memName {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .teamH {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .subT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: left;
  }
  
  .cT {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    z-index: 10000;
  }
  
  .cP {
    font-size: 16px;
    color: white;
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.658);
    padding: 20px;
    border-radius: 50px;
    border: 2px white solid;
  }
  
  .carouselT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  
  }
  
  .carouselT2 {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    display: none;
  }
  
  .cMain {
    z-index: 1;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .team1 {
    width: 30%;
  }
  
  .team1-1 {
    width: 30%;
  }
  
  .storyRight {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
  }
  
  .storyRight2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-bottom: 20%;
    z-index: 1000;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: #f8c831;
    text-align: center;
    font-family: 'KG The Last Time', sans-serif;
    text-shadow: 0 0 7px #f8c831;
  }
  
  .intro img {
    width: 30%;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    text-align: center;
    margin-top: 1%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 10%;
    font-size: 15px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    justify-content: flex-end;
    height: fit-content;
    display: none;
  }

  .rightMobile {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    right: 10%;
    height: fit-content;
    position: fixed;
    bottom: 7%;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    width: 5%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .discord {
    transition: transform .2s;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .logoF {
    padding-bottom: 1%;
    font-size: 40px;
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: center;
    z-index: 20;
    position: relative;
  }
  
  .discord:hover {
    transform: scale(1.4);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitter {
   width: 30px;
   height: 30px;
   display: flex;
   margin-top: auto;
   margin-bottom: auto;
   justify-content: center;
   cursor: pointer;

  }
  
  .twitterDiv{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
   }
  
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30%;
    z-index: 1;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
    transition-duration: .2s;
  }
  
  .connect div:hover {
    color: #f8c831;
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #fe7fc0;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Comic Kings', sans-serif;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: auto;
    margin-right: auto;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    z-index: 1000;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-weight: 500;
    font-size: 17px;
    position: fixed;
     margin-top: 25%;
    bottom: 0;
     width: 100%;
  }
  
  .gif {
    background: url('./assets/sky.gif');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }
  
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .vidCont4 {
    object-fit: cover;
    width: 100%;
  
  }
  
  .videoBg4 {
    position: absolute;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
  }
  
  .vidCont3 {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #2E8B57;
  }
  
  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }
  
  .videoBg2 {
    left: 0px;
    max-width: 100%;
    min-height: 100%;
    background-size: contain;
    position: absolute;
    top: 0;
  }
  
  .videoBg3 {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .name {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .logoDiv {
    width: 130px;
    height: 61px;
  }
  
  .wallet2 {
    background-color: #f8c831;
    color: rgb(23, 7, 66);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 7px;
    font-family: 'KG The Last Time', sans-serif;
    transition-duration: .5s;
  
  }
  
  .learn {
    background-color: #fe7fc100;
    color: white;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(235, 163, 9), rgb(255, 174, 1)) 1;
    transition: transform .4s;
    margin-top: 3%;
    background: -webkit-linear-gradient(rgb(255, 174, 1), rgb(255, 174, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  .learn:hover {
    transform: scale(1.5);
  }
  
  
  #learnFont {
    font-size: 8px;
  }
  
  
  .wallet2:hover {
    background-color: rgb(9, 52, 109);
    color: #f8c831;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    background-color: rgba(202, 55, 55, 0);
    padding: 10%;
    z-index: 20;
    margin-top: 20%;
    position: relative;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    z-index: 20;
    position: relative;
    font-size: 13px;
  }
  
  .socialFDiv {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    z-index: 20;
    position: relative;
  }
  
  .socialF {
    width: 40px;
    height: 40px;
    transition: transform .4s;
    z-index: 100000;
  }
  
  .socialF:hover {
    transform: scale(1.6);
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  #fontSize {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .cover {
    width: 100vw;
  }
  
  .parrotPic {
    width: 50%;
  }
  
  .parrot {
    width: 70%;
  }
  
  .logo2 {
    width: 50%;
    margin-top: 5%;
  }
  
  .blackLine {
    width: fit-content;
  }
  
  .p1 {
    font-size: 14px;
    margin-bottom: 2%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    border-left-color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    border-right-color: #f9f50500;
    border-top-color: #f9f50500;
    border-bottom-color: #f9f50500;
    border-style: dashed;
    border-width: 5px;
    padding-left: 70px;
    padding-bottom: 20px;
  }
  
  .giveaways {
    color: white;
    text-align: left;
    font-weight: 500;
    flex-flow: column nowrap;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .gaPic {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  .section1Li {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    z-index: 1;
  }
  
  .gaPic2 {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  
  .gaPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: block;
    margin-right: 5%;
  
  }
  
  .noteGiveaway {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 17px;
  }
  
  .noteGiveaway2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 16px;
    color: white;
  }
  
  .picAndnote {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmBox {
    color: white;
  }
  
  .section1give {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmMain {
    flex-flow: column nowrap;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .phase {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: left;
  }
  
  .rmCon {
    color: white;
    width: 90%;
    text-align: justify;
    font-size: 20px;
    margin-top: -2%;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 40%;
    margin-bottom: -40%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    background-color: #2E8B57;
  }
  
  .man {
    width: 300px;
    height: 300px;
  }
  
  .manDiv {
    background-color: #3a9ae8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  * {
      box-sizing: border-box;
    }
  
    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    .ticker-wrap {
      width: 100%;
      overflow: hidden;
      height: 2rem;
      background-color: #ffffff;
      padding-left: 100%;
      position: fixed;
      bottom: 0;
    }
  
    .ticker-wrap .ticker {
      display: inline-block;
      height: 2rem;
      line-height: 2rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 30s;
      animation-duration: 50s;
    }
  
    .ticker-wrap .ticker__item {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 16px;
      color: black;
      font-weight: 600;
    }
  
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .rightMobile{
    display: none;
  }  

  .aLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: #f8c831;
  }
  
  .walletLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .cT2 a {
    color: #f8c831;
    text-decoration: none;
  }
  
  .wallet2M a {
    font-family: 'KG The Last Time', sans-serif;
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .wallet2M a:hover {
    color: #f8c831;
  }
  
  .connectAndSocial {
    display: flex;
    flex-flow: column;
    width: 60%;
  }
  
  .button3 {
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 1%;
    text-align: left;
    border: none;
    background-color: #fea676;
    color: white;
    font-weight: lighter;
    font-size: 16px;
    transition: 0.3s ease;
  }
  
  .walletadd{
    color: white;
    background-color: #bdfe00;
    width: 200px;
    padding: 3px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: left;
    border: none;
  
    font-weight: lighter;
    font-size: 16px;
  }
  
  
  .button3:hover {
    color: #fea676;
    background-color: white;
  }
  
  .button3:focus {
    color: white;
    background-color: #bdfe00;
  }
  
  .connectImg {
    width: 70%;
    z-index: 1000;
    margin-bottom: 2%;
  }
  
  .connect2M {
    display: none;
  }
  
  .connect2-2M {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .discord4 {
    display: none;
  }
  
  .com {
    z-index: 1000;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    margin-top: -10%;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    opacity: 0.6;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .osPic {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .storyMain {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('./assets/cave.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Light {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .boxWrap2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap2-3 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
  }
  
  .boxWrap7 {
    padding-top: 3%;
    padding-bottom: 3%;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap8 {
    padding-top: 25%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .goldGost {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .goldGost2 {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
    border: 3px solid white;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: #6bba5e;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .boxWrap3 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3%;
    padding-bottom: 6%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .carouselH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 10%;
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .storyCon2-2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  }
  
  .storyCon2-3 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    font-style: italic;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 60%;
    background-color: #212b3f81;
    padding: 20px;
    margin-right: 10%;
    border-radius: 10px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes p {
    color: rgba(255, 255, 255, 0.753);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .download {
    width: 25px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
  
    }
  
  }
  
  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .storyCon2 {
    color: white;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .underBenefits {
    margin-top: 5%;
    z-index: 1000;
  
  }
  
  .aboutT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    z-index: 1;
    margin-bottom: 3%;
    margin-top: 5%;
  }
  
  .rmT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  }
  
  .memName {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .teamH {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .subT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: left;
  }
  
  .cT {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    z-index: 10000;
  }
  
  .cP {
    font-size: 16px;
    color: white;
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.658);
    padding: 20px;
    border-radius: 50px;
    border: 2px white solid;
  }
  
  .carouselT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  
  }
  
  .carouselT2 {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    display: none;
  }
  
  .cMain {
    z-index: 1;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .team1 {
    width: 30%;
  }
  
  .team1-1 {
    width: 30%;
  }
  
  .storyRight {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
  }
  
  .storyRight2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-bottom: 20%;
    z-index: 1000;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: #f8c831;
    text-align: center;
    font-family: 'KG The Last Time', sans-serif;
    text-shadow: 0 0 7px #f8c831;
  }
  
  .intro img {
    width: 30%;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    text-align: center;
    margin-top: 1%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 10%;
    font-size: 15px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    justify-content: flex-end;
    height: fit-content;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    width: 5%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .discord {
    transition: transform .2s;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .logoF {
    padding-bottom: 1%;
    font-size: 40px;
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: center;
    z-index: 20;
    position: relative;
  }
  
  .discord:hover {
    transform: scale(1.4);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitter {
   width: 30px;
   height: 30px;
   display: flex;
   margin-top: auto;
   margin-bottom: auto;
   justify-content: center;
   cursor: pointer;

  }
  
  .twitterDiv{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
  
  }
  
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30%;
    z-index: 1;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
    transition-duration: .2s;
  }
  
  .connect div:hover {
    color: #f8c831;
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #fe7fc0;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Comic Kings', sans-serif;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: auto;
    margin-right: auto;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    z-index: 1000;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-weight: 500;
    font-size: 17px;
    position: fixed;
     margin-top: 25%;
    bottom: 0;
     width: 100%;
  }
  
  .gif {
    background: url('./assets/sky.gif');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }
  
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .vidCont4 {
    object-fit: cover;
    width: 100%;
  
  }
  
  .videoBg4 {
    position: absolute;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
  }
  
  .vidCont3 {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #2E8B57;
  }
  
  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }
  
  .videoBg2 {
    left: 0px;
    max-width: 100%;
    min-height: 100%;
    background-size: contain;
    position: absolute;
    top: 0;
  }
  
  .videoBg3 {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .name {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .logoDiv {
    width: 130px;
    height: 61px;
  }
  
  .wallet2 {
    background-color: #f8c831;
    color: rgb(23, 7, 66);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 7px;
    font-family: 'KG The Last Time', sans-serif;
    transition-duration: .5s;
  
  }
  
  .learn {
    background-color: #fe7fc100;
    color: white;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(235, 163, 9), rgb(255, 174, 1)) 1;
    transition: transform .4s;
    margin-top: 3%;
    background: -webkit-linear-gradient(rgb(255, 174, 1), rgb(255, 174, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  .learn:hover {
    transform: scale(1.5);
  }
  
  
  #learnFont {
    font-size: 8px;
  }
  
  
  .wallet2:hover {
    background-color: rgb(9, 52, 109);
    color: #f8c831;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    background-color: rgba(202, 55, 55, 0);
    padding: 10%;
    z-index: 20;
    margin-top: 20%;
    position: relative;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    z-index: 20;
    position: relative;
    font-size: 13px;
  }
  
  .socialFDiv {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    z-index: 20;
    position: relative;
  }
  
  .socialF {
    width: 40px;
    height: 40px;
    transition: transform .4s;
    z-index: 100000;
  }
  
  .socialF:hover {
    transform: scale(1.6);
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  #fontSize {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .cover {
    width: 100vw;
  }
  
  .parrotPic {
    width: 50%;
  }
  
  .parrot {
    width: 70%;
  }
  
  .logo2 {
    width: 50%;
    margin-top: 5%;
  }
  
  .blackLine {
    width: fit-content;
  }
  
  .p1 {
    font-size: 14px;
    margin-bottom: 2%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    border-left-color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    border-right-color: #f9f50500;
    border-top-color: #f9f50500;
    border-bottom-color: #f9f50500;
    border-style: dashed;
    border-width: 5px;
    padding-left: 70px;
    padding-bottom: 20px;
  }
  
  .giveaways {
    color: white;
    text-align: left;
    font-weight: 500;
    flex-flow: column nowrap;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .gaPic {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  .section1Li {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    z-index: 1;
  }
  
  .gaPic2 {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  
  .gaPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: block;
    margin-right: 5%;
  
  }
  
  .noteGiveaway {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 17px;
  }
  
  .noteGiveaway2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 16px;
    color: white;
  }
  
  .picAndnote {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmBox {
    color: white;
  }
  
  .section1give {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmMain {
    flex-flow: column nowrap;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .phase {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: left;
  }
  
  .rmCon {
    color: white;
    width: 90%;
    text-align: justify;
    font-size: 20px;
    margin-top: -2%;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 40%;
    margin-bottom: -40%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    background-color: #2E8B57;
  }
  
  .man {
    width: 300px;
    height: 300px;
  }
  
  .manDiv {
    background-color: #3a9ae8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  * {
      box-sizing: border-box;
    }
  
    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    .ticker-wrap {
      width: 100%;
      overflow: hidden;
      height: 2rem;
      background-color: #ffffff;
      padding-left: 100%;
      position: fixed;
      bottom: 0;
    }
  
    .ticker-wrap .ticker {
      display: inline-block;
      height: 2rem;
      line-height: 2rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 30s;
      animation-duration: 50s;
    }
  
    .ticker-wrap .ticker__item {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 16px;
      color: black;
      font-weight: 600;
    }
  
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .rightMobile{
    display: none;
  }  

.aLink {
  color: rgb(255, 255, 255);
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  color: #f8c831;
}

.walletLink {
  color: rgb(255, 255, 255);
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  color: rgb(23, 7, 66);
}

.cT2 a {
  color: #f8c831;
  text-decoration: none;
}

.wallet2M a {
  font-family: 'KG The Last Time', sans-serif;
  color: rgb(255, 255, 255);
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  color: rgb(23, 7, 66);
}

.wallet2M a:hover {
  color: #f8c831;
}

.connectAndSocial {
  display: flex;
  flex-flow: column;
  width: 60%;
}

.button3 {
  width: 200px;
  padding: 3px;
  margin-top: 2%;
  margin-bottom: 1%;
  text-align: left;
  border: none;
  background-color: #fea676;
  color: white;
  font-weight: lighter;
  font-size: 16px;
  transition: 0.3s ease;
}

.walletadd{
  color: white;
  background-color: #bdfe00;
  width: 200px;
  padding: 3px;
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: left;
  border: none;

  font-weight: lighter;
  font-size: 16px;
}


.button3:hover {
  color: #fea676;
  background-color: white;
}

.button3:focus {
  color: white;
  background-color: #bdfe00;
}

.connectImg {
  width: 50%;
  z-index: 1000;
  margin-bottom: 2%;
}

.connect2M {
  display: none;
}

.connect2-2M {
  display: flex;
  flex-flow: row nowrap;
}

.discord4 {
  display: none;
}

.com {
  z-index: 1000;
}

.audioPic {
  /* display: none;*/
  width: 100px;
  height: 100px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  margin-top: -10%;
}

.speakerplace2 {
  margin-left: 15px;
  font-size: 18pt;
  opacity: 0.6;
  margin-top: 12px;
  padding: 6px;
  cursor: pointer;
  background-image: url('./audio/speakeroff.png');
  background-size: cover;
  width: 60px;
  height: 60px;
  border: 0;
  background-color: #41dfff00;
}

.speakerplace1 {
  margin-left: 15px;
  font-size: 18pt;
  margin-top: 12px;
  padding: 6px;
  cursor: pointer;
  background-image: url('./audio/speakeron.png');
  background-size: cover;
  width: 60px;
  height: 60px;
  border: 0;
  background-color: #41dfff00;
}

.osPic {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 22px;
  color: #ffffff;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3%;
  justify-content: center;
}

.storyMain {
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0);
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('./assets/cave.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Light {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3%;
  justify-content: center;
}

.boxWrap2-2 {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
}

.boxWrap2-3 {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
}

.boxWrap5 {
  display: flex;
  flex-flow: column nowrap;
  background-color: black;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
}

.boxWrap7 {
  padding-top: 3%;
  padding-bottom: 3%;

  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-flow: column nowrap;
  display: flex;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap8 {
  padding-top: 25%;
  padding-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-flow: column nowrap;
  display: flex;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.goldGost {
  width: 40%;
  border-radius: 50px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  z-index: 1;
}

.goldGost2 {
  width: 40%;
  border-radius: 50px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  z-index: 1;
  border: 3px solid white;
}

.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
  background-color: #6bba5e;
}

.green {
  background-color: #97ba5e;

}

.boxWrap3 {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 3%;
  padding-bottom: 6%;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  font-size: 70px;
  color: white;
  font-family: 'Comic Kings', sans-serif;
}

.carouselH {
  font-size: 70px;
  color: white;
  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;
  text-align: center;
  margin-bottom: 10%;
}

.mintH {
  font-size: 70px;
  color: white;
  font-family: 'Comic Kings', sans-serif;
  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: justify;
  font-weight: 500;
}

.storyCon2-2 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: left;
  font-weight: 500;
}

.storyCon2-3 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  font-style: italic;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  width: 60%;
  background-color: #212b3f81;
  padding: 20px;
  margin-right: 10%;
  border-radius: 10px;
  border: 2px solid rgb(255, 187, 0);
}

.quotes {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border: 2px solid rgb(255, 187, 0);
}

.quotes p {
  color: rgba(255, 255, 255, 0.753);
  font-size: 20px;
  text-align: justify;
  font-weight: 500;
}

.download {
  width: 25px;
  animation: arrow_down_animation 2s ease-in-out infinite;

}

@keyframes arrow_down_animation {

  0% {
    transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1, 1);
    opacity: 0;

  }

}

.downloadDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 1%;
}

.storyCon2 {
  color: white;
  font-size: 22px;
  text-align: center;
  color: rgb(255, 255, 255);
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
}

.underBenefits {
  margin-top: 5%;
  z-index: 1000;

}

.aboutT {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 40px;
  text-align: center;
  z-index: 1;
  margin-bottom: 3%;
  margin-top: 5%;
}

.rmT {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 100px;
  text-align: center;
}

.memName {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 40px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: white;
  font-size: 17px;
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.teamH {
  font-family: 'KG The Last Time', sans-serif;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 50px;
  text-align: center;
  margin-bottom: 5%;
}

.subT {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 50px;
  text-align: left;
}

.cT {
  font-family: 'KG The Last Time', sans-serif;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 40px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
  z-index: 10000;
}

.cP {
  font-size: 16px;
  color: white;
  text-align: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.658);
  padding: 20px;
  border-radius: 50px;
  border: 2px white solid;
}

.carouselT {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 100px;
  text-align: center;

}

.carouselT2 {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 100px;
  text-align: center;
  z-index: 1;
  display: none;
}

.cMain {
  z-index: 1;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.team1 {
  width: 30%;
}

.team1-1 {
  width: 30%;
}

.storyRight {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 1;
}

.storyRight2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-bottom: 20%;
  z-index: 1000;
}

.uR {
  width: 50%;
}

.intro {
  color: #f8c831;
  text-align: center;
  font-family: 'KG The Last Time', sans-serif;
  text-shadow: 0 0 7px #f8c831;
}

.intro img {
  width: 30%;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  text-align: center;
  margin-top: 1%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 10%;
  font-size: 15px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  z-index: 1;
  justify-content: flex-end;
  height: fit-content;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1%;
  width: 5%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
}

.discord {
  transition: transform .2s;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.logoF {
  padding-bottom: 1%;
  font-size: 40px;
  font-family: 'KG The Last Time', sans-serif;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 60px;
  text-align: center;
  z-index: 20;
  position: relative;
}

.discord:hover {
  transform: scale(1.4);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitter {
 width: 30px;
 height: 30px;
 display: flex;
 margin-top: auto;
 margin-bottom: auto;
 justify-content: center;
 cursor: pointer;

}

.twitterDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 10%;
  margin-left: 10%;

}


.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  width: 30%;
  z-index: 1;
}

.connect2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'KG The Last Time', sans-serif;

}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  cursor: pointer;
  transition-duration: .2s;
}

.connect div:hover {
  color: #f8c831;
}


@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}


.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Comic Kings', sans-serif;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Comic Kings', sans-serif;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  background-color: #fe7fc0;
  color: white;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Comic Kings', sans-serif;
  transition: transform .2s;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 70px;
  font-family: 'Titan One', cursive;
  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: rgb(124, 199, 74);
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin-left: auto;
  margin-right: auto;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  z-index: 1000;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  font-weight: 500;
  font-size: 17px;
  position: fixed;
   margin-top: 25%;
  bottom: 0;
   width: 100%;
}

.gif {
  background: url('./assets/sky.gif');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  height: max-content;
  overflow: hidden;
}


.vidCont {
  object-fit: cover;
  width: 100%;
}

.vidCont4 {
  object-fit: cover;
  width: 100%;

}

.videoBg4 {
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

}

.vidCont3 {
  width: 100%;
  justify-content: center;
  display: flex;
  background-color: #2E8B57;
}

.videoBg {
  position: absolute;
  top: 0;
  left: 0px;
  max-width: 100%;
  min-height: 100%;
}

.videoBg2 {
  left: 0px;
  max-width: 100%;
  min-height: 100%;
  background-size: contain;
  position: absolute;
  top: 0;
}

.videoBg3 {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}

.name {
  width: 30%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  z-index: 1;
}

.logoDiv {
  width: 130px;
  height: 61px;
}

.wallet2 {
  background-color: #f8c831;
  color: rgb(23, 7, 66);
  padding: 12px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 18px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  border-radius: 7px;
  font-family: 'KG The Last Time', sans-serif;
  transition-duration: .5s;

}

.learn {
  background-color: #fe7fc100;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 8px;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, rgb(235, 163, 9), rgb(255, 174, 1)) 1;
  transition: transform .4s;
  margin-top: 3%;
  background: -webkit-linear-gradient(rgb(255, 174, 1), rgb(255, 174, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.learn:hover {
  transform: scale(1.5);
}


#learnFont {
  font-size: 8px;
}


.wallet2:hover {
  background-color: rgb(9, 52, 109);
  color: #f8c831;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  font-family: 'Comic Kings', sans-serif;
  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.footer {
  background-color: rgba(202, 55, 55, 0);
  padding: 10%;
  z-index: 20;
  margin-top: 20%;
  position: relative;
}

.copyright {
  color: rgba(255, 255, 255, 0.712);
  text-align: center;
  z-index: 20;
  position: relative;
  font-size: 13px;
}

.socialFDiv {
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  z-index: 20;
  position: relative;
}

.socialF {
  width: 40px;
  height: 40px;
  transition: transform .4s;
  z-index: 100000;
}

.socialF:hover {
  transform: scale(1.6);
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 133px;
}

#fontSize {
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  font-family: 'KG The Last Time', sans-serif;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.cover {
  width: 100vw;
}

.parrotPic {
  width: 50%;
}

.parrot {
  width: 70%;
}

.logo2 {
  width: 50%;
  margin-top: 5%;
}

.blackLine {
  width: fit-content;
}

.p1 {
  font-size: 14px;
  margin-bottom: 2%;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 70%;
  border-left-color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  border-right-color: #f9f50500;
  border-top-color: #f9f50500;
  border-bottom-color: #f9f50500;
  border-style: dashed;
  border-width: 5px;
  padding-left: 70px;
  padding-bottom: 20px;
}

.giveaways {
  color: white;
  text-align: left;
  font-weight: 500;
  flex-flow: column nowrap;
  justify-content: center;
  display: flex;
  margin-top: 5%;
}

.gaPic {
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: block;
}

.section1Li {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  z-index: 1;
}

.gaPic2 {
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: block;
}


.gaPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  display: block;
  margin-right: 5%;

}

.noteGiveaway {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: block;
  font-size: 17px;
}

.noteGiveaway2 {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: block;
  font-size: 16px;
  color: white;
}

.picAndnote {
  display: flex;
  flex-flow: row nowrap;
}

.rmBox {
  color: white;
}

.section1give {
  flex-flow: row nowrap;
  justify-content: space-between;
}

.rmMain {
  flex-flow: column nowrap;

  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.phase {
  font-family: 'KG The Last Time', sans-serif;
  ;
  color: #f8c831;
  text-shadow: 0 0 7px #f8c831;
  font-size: 60px;
  text-align: left;
}

.rmCon {
  color: white;
  width: 90%;
  text-align: justify;
  font-size: 20px;
  margin-top: -2%;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
}

.year {
  font-weight: 500;
  font-size: 30px;
  text-decoration: underline;
}


.rmBoxMain2 {
  margin-top: 30%;
}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 200px;
  height: 189px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 40%;
  margin-bottom: -40%;
  margin-right: auto;
  display: block;
  margin-left: 24%;
  margin-top: -100%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 20%;
  height: fit-content;
  background-color: #2E8B57;
}

.man {
  width: 300px;
  height: 300px;
}

.manDiv {
  background-color: #3a9ae8;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tAndName {
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

* {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 2rem;
    background-color: #ffffff;
    padding-left: 100%;
    position: fixed;
    bottom: 0;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 16px;
    color: black;
    font-weight: 600;
  }

}

@media screen and (min-width: 1920px) {

  .rightMobile{
    display: none;
  }  

  .aLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: #f8c831;
  }
  
  .walletLink {
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .cT2 a {
    color: #f8c831;
    text-decoration: none;
  }
  
  .wallet2M a {
    font-family: 'KG The Last Time', sans-serif;
    color: rgb(255, 255, 255);
    font-size: large;
    cursor: pointer;
    text-decoration: none;
    color: rgb(23, 7, 66);
  }
  
  .wallet2M a:hover {
    color: #f8c831;
  }
  
  .connectAndSocial {
    display: flex;
    flex-flow: column;
    width: 60%;
  }
  
  .button3 {
    width: 230px;
    padding: 5px;
    margin-top: 2%;
    margin-bottom: 1%;
    text-align: left;
    border: none;
    background-color: #fea676;
    color: white;
    font-weight: lighter;
    font-size: 20px;
    transition: 0.3s ease;
  }
  
  .walletadd{
    color: white;
    background-color: #bdfe00;
    width: 230px;
    padding: 5px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: left;
    border: none;
  
    font-weight: lighter;
    font-size: 20px;
  }
  
  
  .button3:hover {
    color: #fea676;
    background-color: white;
  }
  
  .button3:focus {
    color: white;
    background-color: #bdfe00;
  }
  
  .connectImg {
    width: 50%;
    z-index: 1000;
    margin-bottom: 2%;
  }
  
  .connect2M {
    display: none;
  }
  
  .connect2-2M {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .discord4 {
    display: none;
  }
  
  .com {
    z-index: 1000;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    margin-top: -10%;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    opacity: 0.6;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    background-color: #41dfff00;
  }
  
  .osPic {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .storyMain {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('./assets/cave.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Light {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    justify-content: center;
  }
  
  .boxWrap2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap2-3 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
  }
  
  .boxWrap7 {
    padding-top: 3%;
    padding-bottom: 3%;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap8 {
    padding-top: 25%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .goldGost {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .goldGost2 {
    width: 40%;
    border-radius: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
    border: 3px solid white;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: #6bba5e;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .boxWrap3 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3%;
    padding-bottom: 6%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .carouselH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 10%;
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .storyCon2-2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  }
  
  .storyCon2-3 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    font-style: italic;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 60%;
    background-color: #212b3f81;
    padding: 20px;
    margin-right: 10%;
    border-radius: 10px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border: 2px solid rgb(255, 187, 0);
  }
  
  .quotes p {
    color: rgba(255, 255, 255, 0.753);
    font-size: 20px;
    text-align: justify;
    font-weight: 500;
  }
  
  .download {
    width: 25px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
  
    }
  
  }
  
  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .storyCon2 {
    color: white;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .underBenefits {
    margin-top: 5%;
    z-index: 1000;
  
  }
  
  .aboutT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    z-index: 1;
    margin-bottom: 3%;
    margin-top: 5%;
  }
  
  .rmT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  }
  
  .memName {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .teamH {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .subT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 50px;
    text-align: left;
  }
  
  .cT {
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    z-index: 10000;
  }
  
  .cP {
    font-size: 16px;
    color: white;
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.658);
    padding: 20px;
    border-radius: 50px;
    border: 2px white solid;
  }
  
  .carouselT {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
  
  }
  
  .carouselT2 {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    display: none;
  }
  
  .cMain {
    z-index: 1;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .team1 {
    width: 30%;
  }
  
  .team1-1 {
    width: 30%;
  }
  
  .storyRight {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
  }
  
  .storyRight2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-bottom: 20%;
    z-index: 1000;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: #f8c831;
    text-align: center;
    font-family: 'KG The Last Time', sans-serif;
    text-shadow: 0 0 7px #f8c831;
  }
  
  .intro img {
    width: 30%;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    text-align: center;
    margin-top: 1%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 10%;
    font-size: 15px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    z-index: 1;
    justify-content: flex-end;
    height: fit-content;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    width: 5%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
  }
  
  .discord {
    transition: transform .2s;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .logoF {
    padding-bottom: 1%;
    font-size: 40px;
    font-family: 'KG The Last Time', sans-serif;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: center;
    z-index: 20;
    position: relative;
  }
  
  .discord:hover {
    transform: scale(1.4);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitter {
   width: 45px;
   height: 45px;
   display: flex;
   margin-top: auto;
   margin-bottom: auto;
   justify-content: center;
   cursor: pointer;

  }
  
  .twitterDiv{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
  
  }
  
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30%;
    z-index: 1;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
    transition-duration: .2s;
  }
  
  .connect div:hover {
    color: #f8c831;
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Kings', sans-serif;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #fe7fc0;
    color: white;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Comic Kings', sans-serif;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: auto;
    margin-right: auto;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    z-index: 1000;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-weight: 500;
    font-size: 17px;
    position: fixed;
     margin-top: 25%;
    bottom: 0;
     width: 100%;
  }
  
  .gif {
    background: url('./assets/sky.gif');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }
  
  
  .vidCont {
    object-fit: cover;
    width: 100%;
  }
  
  .vidCont4 {
    object-fit: cover;
    width: 100%;
  
  }
  
  .videoBg4 {
    position: absolute;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
  }
  
  .vidCont3 {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #2E8B57;
  }
  
  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }
  
  .videoBg2 {
    left: 0px;
    max-width: 100%;
    min-height: 100%;
    background-size: contain;
    position: absolute;
    top: 0;
  }
  
  .videoBg3 {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .name {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  
  .logoDiv {
    width: 150px;
    height: 71px;
  }
  
  .wallet2 {
    background-color: #f8c831;
    color: rgb(23, 7, 66);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 7px;
    font-family: 'KG The Last Time', sans-serif;
    transition-duration: .5s;
  
  }
  
  .learn {
    background-color: #fe7fc100;
    color: white;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(235, 163, 9), rgb(255, 174, 1)) 1;
    transition: transform .4s;
    margin-top: 3%;
    background: -webkit-linear-gradient(rgb(255, 174, 1), rgb(255, 174, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  .learn:hover {
    transform: scale(1.5);
  }
  
  
  #learnFont {
    font-size: 8px;
  }
  
  
  .wallet2:hover {
    background-color: rgb(9, 52, 109);
    color: #f8c831;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Comic Kings', sans-serif;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    background-color: rgba(202, 55, 55, 0);
    padding: 10%;
    z-index: 20;
    margin-top: 20%;
    position: relative;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    z-index: 20;
    position: relative;
    font-size: 13px;
  }
  
  .socialFDiv {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    z-index: 20;
    position: relative;
  }
  
  .socialF {
    width: 40px;
    height: 40px;
    transition: transform .4s;
    z-index: 100000;
  }
  
  .socialF:hover {
    transform: scale(1.6);
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  #fontSize {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    font-family: 'KG The Last Time', sans-serif;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .cover {
    width: 100vw;
  }
  
  .parrotPic {
    width: 50%;
  }
  
  .parrot {
    width: 70%;
  }
  
  .logo2 {
    width: 50%;
    margin-top: 5%;
  }
  
  .blackLine {
    width: fit-content;
  }
  
  .p1 {
    font-size: 14px;
    margin-bottom: 2%;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    border-left-color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    border-right-color: #f9f50500;
    border-top-color: #f9f50500;
    border-bottom-color: #f9f50500;
    border-style: dashed;
    border-width: 5px;
    padding-left: 70px;
    padding-bottom: 20px;
  }
  
  .giveaways {
    color: white;
    text-align: left;
    font-weight: 500;
    flex-flow: column nowrap;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .gaPic {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  .section1Li {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    z-index: 1;
  }
  
  .gaPic2 {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
  }
  
  
  .gaPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: block;
    margin-right: 5%;
  
  }
  
  .noteGiveaway {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 17px;
  }
  
  .noteGiveaway2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: block;
    font-size: 16px;
    color: white;
  }
  
  .picAndnote {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmBox {
    color: white;
  }
  
  .section1give {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmMain {
    flex-flow: column nowrap;
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .phase {
    font-family: 'KG The Last Time', sans-serif;
    ;
    color: #f8c831;
    text-shadow: 0 0 7px #f8c831;
    font-size: 60px;
    text-align: left;
  }
  
  .rmCon {
    color: white;
    width: 90%;
    text-align: justify;
    font-size: 20px;
    margin-top: -2%;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 40%;
    margin-bottom: -40%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    background-color: #2E8B57;
  }
  
  .man {
    width: 300px;
    height: 300px;
  }
  
  .manDiv {
    background-color: #3a9ae8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  * {
      box-sizing: border-box;
    }
  
    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
  
      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }
  
    .ticker-wrap {
      width: 100%;
      overflow: hidden;
      height: 2rem;
      background-color: #ffffff;
      padding-left: 100%;
      position: fixed;
      bottom: 0;
    }
  
    .ticker-wrap .ticker {
      display: inline-block;
      height: 2rem;
      line-height: 2rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 30s;
      animation-duration: 50s;
    }
  
    .ticker-wrap .ticker__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 18px;
      color: black;
      font-weight: 600;
    }
  
}